import React from "react";
import {
  Link
} from "react-router-dom";

// React Bootstrap
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

// Assets
import Bitmoji from "../assets/GarrettBitmoji.png";

export default function CustomNavbar() {
  return (
    <Navbar collapseOnSelect bg="dark" variant="dark" expand="lg" className="py-3">
      <Navbar.Brand href="/">
        <img
          alt=""
          src={Bitmoji}
          width="30"
          height="30"
          className="d-inline-block align-top"
        />{" "}
        Garrett Sweeney
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Navbar.Text>
          Software Engineer
        </Navbar.Text>
      </Navbar.Collapse>
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text style={{color: 'yellow'}}>
          garrett.d.sweeney@gmail.com
        </Navbar.Text>
      </Navbar.Collapse>
      
    </Navbar>
  );
}
