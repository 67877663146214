import React, { useState } from 'react';
import { connect } from 'react-redux'

import { 
    Alert, 
    Button,
    Row 
  }  from 'react-bootstrap';

import { toggleBox } from '../actions';

const BuiltWithAlert = ({ box, toggleBox }) => {
    if (box.showBox) {
      return (
        <div>
          <Alert variant="success" onClose={() => toggleBox()} dismissible>
            <Alert.Heading>Garrett, what did you use to build this?</Alert.Heading>
            <Row style={{ 'height': 16}} />
            <p>
            <p>I built this site using <a href="https://reactjs.org/" rel="noopener noreferrer" target="_blank">React</a> and a touch of <a href="https://redux.js.org/" rel="noopener noreferrer" target="_blank">Redux</a>.</p>
              <p>I used to <a href="https://docs.aws.amazon.com/AmazonS3/latest/dev/WebsiteHosting.html" rel="noopener noreferrer" target="_blank">host it on Amazon S3</a> with <a href="https://aws.amazon.com/cloudfront/" rel="noopener noreferrer" target="_blank">Amazon Cloudfront</a> as my CDN, but have since moved it to Netlify.</p>
            </p>
            <p>Check out the <a href="https://github.com/gsweene2/garrettsweeney.com" rel="noopener noreferrer" target="_blank">source code</a> on my Github.</p>
          </Alert>
          <Row style={{ 'height': 16}} />
        </div>
      );
    }
    return (
      <div>
        <Button variant="outline-success" onClick={() => toggleBox()}>Garrett, what did you use to build this?</Button>
        <Row style={{ 'height': 32}}></Row>
      </div>
    );
}

const mapStateToProps = (state) => ({
    box: state.box
})

const mapDispatchToProps = (dispatch) => ({
    toggleBox: () => dispatch(toggleBox()),
})

export default connect(mapStateToProps, mapDispatchToProps)(BuiltWithAlert);
