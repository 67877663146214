import React from 'react';

// React Bootstrap
import { Nav, Navbar } from 'react-bootstrap';

export default function CustomFooter(props) {
    return (
        <Navbar className="justify-content-center" bg="light" variant="dark">
            <Nav.Item>
                <Nav.Link eventKey="disabled" disabled>
                    &#169; Garrett Sweeney {new Date().getFullYear()}
            </Nav.Link>
            </Nav.Item>
        </Navbar>
    );
}