import React from 'react';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

import resume from '../assets/Interviewer_2021_07_20.pdf';

// The button to download the current file

function InterviewerResume() {
  return (
    <iframe src={resume} style={{width: '100%', minHeight: '100vh'}}/>
  );
}

export default InterviewerResume;