import React from "react";

import { CardDeck, Col, Row } from "react-bootstrap";

// Custom Components
import CustomCard from "./CustomCard";
import CustomFooter from "./CustomFooter";
import BuiltWithAlert from "./BuiltWithAlert";

// Assets
import Ghost from "../assets/ghost.svg";
import Medium from "../assets/Monogram.svg";
import Github from "../assets/Octocat.png";
import ReadTheDocs from "../assets/ReadTheDocsImage.png";
import AWSDevopsProBadge from "../assets/awsDevopsProfessional.png";
import Rochester from "../assets/Rochester.png";
import BookApptNow from "../assets/BookApptNowTransparent.png"

// CSS
import "./Home.css";

function Home() {
  return (
    <div className="Home">
      <Row style={{ height: 50 }}></Row>
      <Row style={{ display: 'flex' }}>
        <Col md={4} style={{ flexGrow: 1, padding: '15px' }}>
          <CustomCard
            title="Medium"
            image={Medium}
            detail="Check out my posts on Serverless framework, Python, Terraform, AWS, & more!"
            buttonLink="https://garrettdsweeney.medium.com/"
            buttonText="To Medium"
          />
        </Col>
        <Col md={4} style={{ flexGrow: 1, padding: '15px' }}>
          <CustomCard
            title="Slack Bots"
            image={Ghost}
            detail="I had my simple Ghost Buster Bot accepted into the Slack App Directory!"
            buttonLink="https://garrettshouse.slack.com/apps/AQ3001GTG-ghost-buster"
            buttonText="To Slack Directory"
          />
        </Col>
        <Col md={4} style={{ flexGrow: 1, padding: '15px' }}>
          <CustomCard
            title="Docs"
            image={ReadTheDocs}
            detail="I various notes in a Read The Docs project"
            buttonLink="https://docs.garrettsweeney.com"
            buttonText="To ReadTheDocs"
          />
        </Col>
        <Col md={4} style={{ flexGrow: 1, padding: '15px' }}>
          <CustomCard
            title="AWS Certifications"
            image={AWSDevopsProBadge}
            detail="I build in AWS, and have achieved several Certifications to develop knowedge."
            buttonLink="https://www.youracclaim.com/users/garrettsweeney/badges"
            buttonText="To Acclaim"
          />
        </Col>
        <Col md={4} style={{ flexGrow: 1, padding: '15px' }}>
          <CustomCard
            title="LinkedIn"
            image={Rochester}
            detail="Interested in academia and work history? Check out my LinkedIn profile"
            buttonLink="https://www.linkedin.com/in/garrett-sweeney-919b7335/"
            buttonText="To LinkedIn"
          />
        </Col>
        <Col md={4} style={{ flexGrow: 1, padding: '15px' }}>
          <CustomCard
            title="Github"
            image={Github}
            detail="I keep a few things on Github, mainly Terraform and POC Serverless framework examples."
            buttonLink="https://github.com/gsweene2"
            buttonText="To Github"
          />
        </Col>
      </Row>
      <Row style={{ height: 50 }}></Row>
      <BuiltWithAlert />
    </div>
  );
}

export default Home;
