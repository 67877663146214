import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import configureStore from "./configureStore";

// Styles
import "./App.css";

// Custom Components
import Home from "./components/Home";
import CustomNavbar from "./components/CustomNavbar";
import CustomFooter from "./components/CustomFooter";

// Assets
import Bitmoji from "./assets/GarrettBitmoji.png";
import Resume from "./components/Resume";
import InterviewerResume from "./components/InterviewerResume";

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <Router>
        <CustomNavbar />
        <Container>
          <Switch>
            <Route exact path="/resume">
              <Resume />
            </Route>
            <Route exact path="/interviewer">
              <InterviewerResume/>
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
          </Switch>
        </Container>
        <img src={Bitmoji} style={{width: 350, height: 350}}></img>
        <CustomFooter /> 
      </Router>
    </Provider>
  );
}

export default App;
