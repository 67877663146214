import React from 'react';
import './CustomCard.css';

// React Bootstrap
import { Button, Card } from 'react-bootstrap';

export default function CustomCard(props) {
    return (
        <Card style={{height: '100%'}}>
            <Card.Header style={{borderBottom: "none", backgroundColor: "white"}}>
                <Card.Title>{props.title}</Card.Title>
            </Card.Header>
            <Card.Body>
                <div className="cardContainer">
                    <div className="imageContainer">
                        <Card.Img variant="top"
                            src={props.image}
                            className="cardImage"
                        />
                    </div>
                    <Card.Text className="cardText">
                        {props.detail}
                    </Card.Text>
                </div>
            </Card.Body>
            <Card.Footer style={{backgroundColor: 'white', borderTop: "none"}}>
                <Button variant="primary"
                    href={props.buttonLink}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="cardButton"
                >{props.buttonText}</Button>
            </Card.Footer>
        </Card>
    );
}