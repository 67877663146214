import React from 'react';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

import resume from '../assets/GarrettSweeney2021_05_01.pdf';

function Resume() {
  return (
    <iframe src={resume} style={{width: '100%', minHeight: '100vh'}}/>

  );
}

export default Resume;